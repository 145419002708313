<template>
  <div>

    <v-autocomplete
        v-model="selectedItems"
        :items="clientListOrdered"
        item-value="url"
        item-text="url"
        :error-messages="error"
        return-object
        small-chips
        @change="checkForExistence"
        label="Sélection de la base"
    >

      <template v-slot:item="{item}">
        <v-list-item-content>
          <v-list-item-subtitle>{{ item.client }}</v-list-item-subtitle>
          <v-list-item-title>{{ item.url }}*</v-list-item-title>
        </v-list-item-content>
      </template>

    </v-autocomplete>

  </div>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "ClientPickerSelect",
  props: {
    value: String,
    alreadyExists: Array
  },
  computed: {
    clientListOrdered() {
      let list = []

      for (let client of this.clientList) {
        for (let child of client.bases) {
          let base = {
            url: child,
            client: client.name
          }
          list.push(base)
        }
      }
      return list
    }
  },
  data() {
    return {
      clientList: [],
      loadingClientList: false,
      selectedItems: [],
      error: null,
      initialClient: []
    }
  },
  methods: {
    checkForExistence() {
      if (this.selectedItems == null) {
        this.$emit('input', null)
      } else {
        let alreadyExists =
            this.alreadyExists.indexOf(this.selectedItems.url) !== -1
            &&
            this.selectedItems.url !== this.value
        if (!alreadyExists) {
          this.error = null
          this.$emit('input', this.selectedItems.url)
        } else {
          this.error = "Ce client a déjà une configuration existante"
        }
      }
    }
  },
  async mounted() {
    this.loadingClientList = true
    let bases = await PlaneteOnlineService.getBases(false)
    this.clientList = bases
    this.loadingClientList = false
    this.selectedItems = this.value
  },
  watch: {
    value(nValue) {
      this.selectedItems = nValue
    }
  }
}
</script>

<style scoped>

</style>